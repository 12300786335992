
export const swiperTestimonios = () => {
    new Swiper(".swiper-testimonios", {
                
        slidesPerView: 1,
        spaceBetween: 16,
        loop: true,
        autoplay: true,
        pagination: {
          el: ".swiper-pagination",
          dynamicBullets: true,
        },
        breakpoints: {
            768: {
                slidesPerView: 2,
            },
            992: {
                slidesPerView: 3,
            }
        }
    }); 
};

export const swiperHero = () => {
    new Swiper(".swiper-hero", {
                
        slidesPerView: 1,
        spaceBetween: 0,
        loop: true,
        direction: "vertical",
        autoplay: true,
        effect: "fade",
        pagination: {
          el: ".swiper-pagination"
        }
    }); 
};

export const swiperProfesionales = () => {
    new Swiper(".swiper-profesionales", {
                
        slidesPerView: 1,
        spaceBetween: 16,
        loop: false,
        autoplay: true,
        centeredSlides: true,
        breakpoints: {
            768: {
                slidesPerView: 3.5,
                spaceBetween: 32,
            },
            992: {
                slidesPerView: 4.5,
            }
        }
/*         pagination: {
          el: ".swiper-pagination"
        } */
    }); 
};

export const swiperCards = () => {
    new Swiper(".swiper-cards", {
                
        slidesPerView: 1,
        spaceBetween: 16,
        loop: true,
        autoplay: true,
        breakpoints: {
            768: {
                slidesPerView: 3,
                spaceBetween: 0,
                loop: false,
                autoplay: false,
            }
        },
        pagination: {
          el: ".swiper-pagination"
        }
    }); 
};
